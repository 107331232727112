const SvgTwitter = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.214 21.762c9.057 0 14.01-7.506 14.01-14.015 0-.213-.004-.425-.013-.637a10.015 10.015 0 002.456-2.55 9.812 9.812 0 01-2.828.775 4.943 4.943 0 002.165-2.724 9.875 9.875 0 01-3.126 1.195 4.926 4.926 0 00-8.39 4.492A13.98 13.98 0 012.336 3.152a4.914 4.914 0 00-.667 2.476c0 1.709.87 3.217 2.191 4.1a4.887 4.887 0 01-2.23-.616v.063a4.927 4.927 0 003.95 4.828 4.926 4.926 0 01-2.224.085 4.93 4.93 0 004.6 3.421 9.876 9.876 0 01-6.116 2.108 10 10 0 01-1.174-.068 13.935 13.935 0 007.548 2.213"
      fill="currentColor"
    />
  </svg>
)

export default SvgTwitter
