import styled from 'styled-components'
import SvgFacebook from './Facebook'
import SvgInstagram from './Instagram'
import SvgTwitter from './Twitter'

const SocialMedia = () => {
  return (
    <SocialWrapper>
      <a
        href="http://instagram.com/policyscout"
        target="_blank"
        rel="nofollow noreferrer noopener"
        aria-label="Instagram"
      >
        <SvgInstagram />
      </a>
      <a
        href="http://twitter.com/policyscout"
        target="_blank"
        rel="nofollow noreferrer noopener"
        aria-label="Twitter"
      >
        <SvgTwitter />
      </a>
      <a
        href="http://facebook.com/policyscout"
        target="_blank"
        rel="nofollow noreferrer noopener"
        aria-label="Facebook"
      >
        <SvgFacebook />
      </a>
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  margin: 1.2rem 0;
  a {
    padding: 1px;
    color: ${({ theme }) => theme.colors.sectionHeading};

    & + a {
      margin-left: calc(1rem - 2px);
    }

    &:hover {
      color: ${({ theme }) => theme.colors.brand.dark};
    }
  }
`

export default SocialMedia
