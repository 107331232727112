const SvgFacebook = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 12.024c0-6.64-5.373-12.024-12-12.024-6.628 0-12 5.383-12 12.024 0 6.002 4.388 10.976 10.125 11.878V15.5H7.745v-3.476h3.047V9.375c0-3.014 1.791-4.678 4.532-4.678 1.313 0 2.687.235 2.687.235V7.89h-1.514c-1.49 0-1.955.927-1.955 1.878v2.255h3.328l-.532 3.476h-2.796v8.402c5.737-.902 10.125-5.876 10.125-11.878z"
      fill="currentColor"
    />
  </svg>
)

export default SvgFacebook
