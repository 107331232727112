import styled from 'styled-components'

import VerticalIcon from 'src/components/icons/vertical'
import { Content } from './Components'

export const VerticalRaw = ({ hit, components, url, className }) => {
  const verticalSlug = hit?.slug?.replace('-insurance', '')

  return (
    <a className={className} href={url}>
      <Content>
        <div>
          <VerticalIcon icon={verticalSlug} />
        </div>

        <h4>
          <components.Highlight hit={hit} attribute="title" />
        </h4>
      </Content>
    </a>
  )
}

export const Vertical = styled(VerticalRaw)`
  display: block;
  text-decoration: none;
  color: black;
  padding: 0.5em;

  ${Content} {
    display: flex;
    align-items: center;
    width: 100%;

    h4 {
      margin: 0;
      padding: 0 0 0 1em;
      display: block;
    }
  }
`

export default Vertical
