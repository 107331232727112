function SvgMapSigns(props) {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.844 2.656L15.5 1.312A1.077 1.077 0 0014.781 1H10V.5c0-.25-.25-.5-.5-.5h-1c-.281 0-.5.25-.5.5V1H2.75a.74.74 0 00-.75.75v2.5c0 .438.313.75.75.75h12.031c.25 0 .5-.094.719-.281l1.344-1.344a.53.53 0 000-.719zM8 15.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5V12H8v3.5zM15.25 7H10V6H8v1H3.187c-.25 0-.5.125-.687.313L1.125 8.655a.53.53 0 000 .719L2.5 10.719a.97.97 0 00.688.281H15.25a.74.74 0 00.75-.75v-2.5a.76.76 0 00-.75-.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMapSigns
