function SvgCall(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle' }}
      {...props}
    >
      <path
        d="M4.785 1.006c-.23 0-.534 0-.91.081a6.418 6.418 0 00-.868.246c-.426.246-.878.736-1.356 1.635a4.7 4.7 0 00-.472 1.107c-.001.003-.003.006-.003.01-.04.143-.067.29-.093.435-.012.066-.03.131-.04.197-.015.11-.017.22-.024.33-.006.093-.019.186-.019.277v.016c0 .245.013.407.038.653.034.245.09.49.166.736.077.273.137.476.18.613l.208.532c.025.064.031.09.06.163.127.327.204.573.23.654.163.449.348.867.546 1.266.16.338.328.663.514.962.432.7.98 1.411 1.614 2.138.351.416.723.84 1.136 1.276.776.76 1.533 1.403 2.275 1.947.372.281.742.545 1.107.771a11.093 11.093 0 001.825.898c.144.057.275.122.423.175.077.026.273.102.614.23.127.048.168.061.268.096.102.042.19.074.263.094.048.017.151.053.184.066.048.015.15.043.216.062.118.053.218.106.392.158.128 0 .248.02.363.048l.06.016c.108.03.212.063.307.1h.68c.784 0 1.575-.205 2.376-.64.861-.478 1.38-.927 1.559-1.354a3.06 3.06 0 00.136-.394l.001-.005c.045-.143.088-.306.131-.47.085-.408.128-.653.128-.899 0-.163-.013-.245-.038-.327-.007-.01-.055-.043-.069-.058a1.525 1.525 0 00-.34-.274l-.01.014a7.413 7.413 0 00-.554-.337c-.162-.082-.391-.245-.69-.409-.298-.163-.57-.245-.817-.408-.24-.164-.466-.246-.679-.41-.034 0-.14-.08-.32-.243a3.775 3.775 0 01-.46-.246c-.119-.082-.238-.082-.357-.082-.17 0-.384.082-.639.328-.256.245-.492.572-.704.817a5.262 5.262 0 01-.677.818c-.239.245-.435.327-.588.327a.47.47 0 01-.296-.083c-.11 0-.195-.081-.255-.081s-.162-.082-.307-.163c-.144-.082-.225-.164-.242-.164-1.168-.654-2.17-1.39-3.004-2.207-.835-.818-1.577-1.88-2.225-3.025-.008 0-.06-.083-.153-.246-.085-.164-.145-.246-.179-.328-.025 0-.06-.08-.102-.244a.53.53 0 01-.064-.246c0-.163.12-.409.358-.654.247-.246.51-.408.792-.653.29-.164.555-.41.794-.655.247-.327.37-.49.37-.653 0-.163-.03-.245-.09-.409-.06-.082-.153-.245-.28-.408-.12-.246-.192-.328-.218-.328-.127-.245-.26-.49-.397-.736-.137-.245-.285-.49-.447-.818-.162-.245-.29-.491-.384-.654-.298-.573-.523-.899-.677-.981h-.267z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCall
