import { useState, useEffect } from 'react'
import Router from 'next/router'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import Link from 'next/link'
import Search from 'src/components/Search'
import { StyledAnchor } from 'src/styles/Button'
import grabColor from 'src/utils/grabColor'
import SvgMenuOpen from 'src/components/icons/MenuOpen'
import SvgMenuClosed from 'src/components/icons/MenuClosed'
import { Logo } from 'src/components/icons/Logo'
import SvgCall from 'src/components/icons/Call'
import { H4 } from 'src/styles/Typography'
import SignOut from '../Auth/SignOut'
// import VerticalIcon from 'src/components/icons/vertical'
import Navigation from './Navigation'
import useSearchToggle from '../Search/useSearchToggle'
import { Auth, DataStore } from 'aws-amplify'

function Header({
  verticals,
  vertical = null,
  apply = false,
  disableFlyout = false,
  disableLink = false,
  govAgency = false,
  overridePhoneNumber = '1-888-912-2132',
  design = 'a',
}) {
  const [isOpen, setIsOpen] = useState(false)
  const desktopSearch = useSearchToggle(false)
  const [currentUser, setCurrentUser] = useState('')
  const [loading, isLoading] = useState(true)

  const verticalSlug = vertical?.slug?.replace('-insurance', '')
  const isMedicare = verticalSlug === 'medicare'
  const handleSignOut = async () => {
    await SignOut()
    setCurrentUser(false)
    Router.push('/')
  }

  const { asPath } = useRouter()
  useEffect(() => {
    setIsOpen(false)
  }, [asPath])

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setCurrentUser(user)
      } catch (e) {
        setCurrentUser('')
      }
    }
    getUser()
    isLoading(false)
  }, [])

  return (
    <HeaderWrapper>
      <Container>
        <LeftWrapper apply={apply}>
          <div>
            <Logo smallMobile={apply && design === 'b'} />
            {govAgency && <NonGovAgency>non-governmental agency</NonGovAgency>}
          </div>

          <MobileHamburger
            onClick={() => setIsOpen((x) => !x)}
            hide={apply && design === 'b'}
            data-cy="mobile-hamburger"
          >
            {isOpen ? <SvgMenuOpen /> : <SvgMenuClosed />}
          </MobileHamburger>

          {apply && design === 'b' && (
            <ApplyWrapperPhoneMobile>
              <BLeft></BLeft>
              <StyledTeleMobile
                href={`tel:${overridePhoneNumber}`}
                className="header-tele"
              >
                <SvgCall /> {overridePhoneNumber}
              </StyledTeleMobile>
              <div className="hours">TTY Users 711</div>
              <div className="hours">9:00am - 7:00pm ET</div>
            </ApplyWrapperPhoneMobile>
          )}
        </LeftWrapper>

        <HeaderRightWrapper isOpen={isOpen}>
          <Navigation
            verticals={verticals}
            vertical={vertical}
            desktopSearch={desktopSearch}
            disableFlyout={disableFlyout}
            disableLink={disableLink}
            currentUser={currentUser}
            handleSignOut={handleSignOut}
          />

          {apply && design === 'a' && (
            <ApplyWrapper>
              <H4>
                {isMedicare
                  ? 'Call to speak with a licensed insurance agent'
                  : 'Get Free Licensed Help'}
              </H4>
              <H4>
                <StyledTel href={`tel:${overridePhoneNumber}`} className="header-tele">
                  <SvgCall /> {overridePhoneNumber} <span>TTY: 711</span>
                </StyledTel>
                <div className="hours-float">9:00am - 7:00pm ET</div>
              </H4>
            </ApplyWrapper>
          )}

          {apply && design === 'b' && (
            <ApplyWrapper>
              <BLeft>
                <H4>Speak with a licensed insurance agent!</H4>
                <div className="hours">9:00am - 7:00pm ET</div>
              </BLeft>
              <StyledTele
                variant="secondary"
                href={`tel:${overridePhoneNumber}`}
                className="header-tele"
              >
                <SvgCall /> {overridePhoneNumber} <span>·</span>
                <span>TTY Users 711</span>
              </StyledTele>
            </ApplyWrapper>
          )}

          {!apply && !currentUser ? (
            <>
              <SearchWrapper verticalSlug={verticalSlug}>
                <Search desktopSearch={desktopSearch} />
              </SearchWrapper>

              <AccountItems>
                <Link href="/login" passHref legacyBehavior>
                  <SignIn>Sign In</SignIn>
                </Link>

                <Link href="/signup" passHref legacyBehavior>
                  <StyledAnchor variant="secondary">Create Account</StyledAnchor>
                </Link>
              </AccountItems>
            </>
          ) : (
            !apply &&
            currentUser && (
              <>
                <SearchWrapper verticalSlug={verticalSlug}>
                  <Search desktopSearch={desktopSearch} />
                </SearchWrapper>

                <AccountItems>
                  <SignIn onClick={handleSignOut}>Sign Out</SignIn>

                  <Link href="/account/settings/notifications" passHref legacyBehavior>
                    <StyledAnchor variant="secondary">My Account</StyledAnchor>
                  </Link>
                </AccountItems>
              </>
            )
          )}
        </HeaderRightWrapper>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

// const VerticalAnchor = styled.a`
//   text-decoration: none;
//   @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
//     display: none;
//   }
// `

// const VerticalIconStyle = styled.span`
//   display: inline-block;
//   vertical-align: middle;
//   margin-right: 1rem;
//   svg {
//     max-width: 23px;
//   }
// `

// const SectionHeadingWithColor = styled(SectionHeading)`
//   flex: 1;
//   margin: 0 0 0 50px;
//   color: ${({ verticalSlug, theme }) =>
//     verticalSlug ? theme.colors[verticalSlug].DEFAULT : theme.colors.brand.DEFAULT};
// `

const NonGovAgency = styled.span`
  display: block;
  font-size: 10px;
  color: ${grabColor('detail')};
  margin-top: -4px;
  margin-bottom: -10px;
  text-align: end;
`

const StyledTel = styled.a`
  text-decoration: none;
  color: ${grabColor('base')};

  span {
    font-weight: normal;
    padding-left: 0.5rem;
  }
  &:hover {
    text-decoration: underline;
  }
`

const StyledTele = styled(StyledAnchor)`
  text-decoration: none;
  color: ${grabColor('base')};

  span {
    display: inline-block;
    font-weight: normal;
    padding-left: 0.5rem;
  }

  svg {
    width: 15px;
  }
`

const StyledTeleMobile = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${grabColor('base')};
  font-weight: 500;
  font-size: 14px;

  span {
    display: inline-block;
    font-weight: normal;
    padding-left: 0.5rem;
  }

  svg {
    display: inline-block;
    width: 15px;
  }
`

const ApplyWrapperPhoneMobile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  display: none;
  svg {
    margin-right: 10px;
    margin-top: -1px;
  }

  .hours {
    font-size: 11px;
    font-weight: normal;
    text-align: end;
  }

  .hours-float {
    font-size: 11px;
    font-weight: normal;
    position: absolute;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
  }
`

const ApplyWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  svg {
    margin-right: 10px;
    margin-top: -1px;
  }

  .hours {
    font-size: 11px;
    font-weight: normal;
    text-align: end;
  }

  .hours-float {
    font-size: 11px;
    font-weight: normal;
    position: absolute;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

const BLeft = styled.div`
  ${H4} {
    margin-top: -4px;
    margin-bottom: 4px;
  }
`

const HeaderRightWrapper = styled.header`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    transition: all 300ms ease-in-out;
    margin-top: ${({ isOpen }) => (isOpen ? '0' : '-200px')};
  }
`

const MobileHamburger = styled.button`
  display: none;
  color: ${({ theme }) => theme.colors.mobileMenuIcon};
  cursor: pointer;
  border: unset;
  background: unset;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: ${({ hide }) => (hide ? 'none' : 'block')};
  }
`

const SignIn = styled.a`
  margin: 0 1rem;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${grabColor('heading')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.action.DEFAULT};
  }
`

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 25px 0 23px;
  border-bottom: 1px solid #e3ebed;
  background: white;
  z-index: 100;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    border: unset;
    padding: 0;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5rem 0;
  max-width: calc(1240px + 3rem);
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  background: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    border-bottom: 1px solid #e3ebed;
    padding: 25px 1.5rem 23px 24px;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;

    ${({ apply }) =>
      apply &&
      `
      padding: 18px 1.5rem 16px 24px;
    `};
  }
`

const AccountItems = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

const SearchWrapper = styled.div`
  margin-right: 3px;
  display: block;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
    margin: 0;
    border-bottom: 3px solid
      ${({ verticalSlug, theme }) =>
        verticalSlug ? theme.colors[verticalSlug].DEFAULT : theme.colors.brand.DEFAULT};
  }
`
