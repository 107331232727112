const links = {
  life: {
    guide: [
      {
        title: 'What is Term Life Insurance?',
        href: '/life-insurance/learn/term-life-insurance',
      },
      {
        title: 'What is Permanent Life Insurance?',
        href: '/life-insurance/learn/permanent-life-insurance',
      },
      {
        title: 'What is Final Expense Life Insurance?',
        href: '/life-insurance/learn/final-expense-life-insurance',
      },
      {
        title: 'What Is Mortgage Life Insurance?',
        href: '/life-insurance/learn/mortgage-life-insurance',
      },
    ],
    topPicks: [
      {
        title: 'How Much Life Insurance Do I Really Need',
        href: '/life-insurance/learn/much-life-insurance-really-need',
      },
      {
        title: 'What Types of Life Insurance Are There?',
        href: '/life-insurance/learn/types-life-insurance',
      },
      {
        title: 'How To Get The Most Cash Value From Life Insurance',
        href: '/life-insurance/learn/cashvalue-life-insurance',
      },
      {
        title: 'How To Convert A Term Life Insurance Plan',
        href: '/life-insurance/learn/everything-you-need-to-know-about-convertible-term-life-insurance',
      },
    ],
  },
  health: {
    guide: [
      {
        title: 'Guide To Choosing Health Insurance',
        href: '/health-insurance/learn/choosing-health-insurance-policy-guide',
      },
      {
        title: 'Guide To Health insurance Enrollment',
        href: '/guide-to-health-insurance-enrollment',
      },
      {
        title: 'Guide To Metal Tiers In Health Insurance',
        href: '/health-insurance/learn/metal-tiers-health-insurance',
      },
      {
        title: 'Deductibles Vs. Out Of Pocket Limits',
        href: '/health-insurance/learn/out-of-pocket-limit-vs-deductible-whats-the-difference',
      },
    ],
    topPicks: [
      {
        title: 'Private Vs. Public Health insurance',
        href: '/private-vs-public-health-insurance',
      },
      {
        title: 'Guide To Disability Insurance',
        href: '/health-insurance/learn/how-much-disability-insurance',
      },
      {
        title: 'Costs Of Childbirth By State',
        href: '/health-insurance/learn/costs-childbirth-by-state',
      },
      {
        title: 'Health Insurance For Freelancers',
        href: '/health-insurance/learn/health-insurance-freelancers',
      },
    ],
  },
  medicare: {
    guide: [
      {
        title: 'How Does Medicare Work?',
        href: '/medicare/learn/how-does-medicare-work',
      },
      {
        title: 'Guide To Open Enrollment ',
        href: '/medicare/learn/medicare-open-enrollment',
      },
      {
        title: 'Guide To Medicare Five Star Plans',
        href: '/medicare/learn/five-star-medicare-plans ',
      },
      {
        title: 'Difference Between Medicare And Medicaid',
        href: '/medicare/learn/difference-between-medicare-and-medicaid',
      },
    ],
    topPicks: [
      {
        title: 'Medicare Flex Cards For Seniors',
        href: '/medicare/learn/flex-cards-for-seniors',
      },
      {
        title: 'How To Change Medicare Supplement Plans',
        href: '/medicare/learn/changing-medicare-supplement-plans',
      },
      {
        title: 'Medicare Renewal',
        href: '/medicare/learn/medicare-renewal',
      },
      {
        title: 'Medicare Eligibility',
        href: '/medicare/learn/medicare-eligibility-a-through-d',
      },
    ],
  },
  home: {
    guide: [
      {
        title: 'Types of Home Insurance Plans',
        href: '/types-of-home-insurance-plans',
      },
      {
        title: 'Homeowners Insurance Without An Inspection',
        href: '/home-insurance/learn/homeowners-insurance-without-inspection',
      },
      {
        title: 'Homeonwers Insurance With a Bad Roof',
        href: '/home-insurance/learn/homeowners-insurance-bad-roof',
      },
      {
        title: 'How To Utilize Your Home Insurance Claim Money',
        href: '/home-insurance/learn/using-home-insurance-claim-money',
      },
    ],
    topPicks: [
      {
        title: 'Most Common Homeowners Claims',
        href: '/home-insurance/learn/most-common-homeowners-insurance-claims',
      },
      // {
      //   title: 'What To Do If You Fail A Homeowners Inspection',
      //   href: '/home-insurance/learn/failed-homeowners-insurance-inspection/',
      // },
      {
        title: 'Renters Insurance',
        href: '/renters-insurance',
      },
      {
        title: 'Does Home Insurance Cover Water Damage',
        href: '/home-insurance/learn/water-damage-home-insurance-cover',
      },
      {
        title: '10 Areas Not Homeowners Insurance Might Not Cover',
        href: '/home-insurance/learn/10-homeowners-insurance-might-not-cover',
      },
    ],
  },
  auto: {
    guide: [
      {
        title: 'How To Get Car Insurance Before Buying A Car',
        href: '/auto-insurance/learn/how-to-get-car-insurance-before-buying-car',
      },
      {
        title: 'Is Car Insurance Cheaper If You Drive 25 Miles Or Less',
        href: '/auto-insurance/learn/car-insurance-cheaper-drive-less-25-miles-day',
      },
      {
        title: 'Tips For Buying A Car Remotely',
        href: '/auto-insurance/learn/how-to-buy-car-remotely',
      },
      {
        title: 'What is Bumper to Bumper Warranty and what does it cover?',
        href: '/auto-insurance/learn/bumper-to-bumper-warranty-cover',
      },
    ],
    topPicks: [
      {
        title: 'Liability Vs. Full Coverage ',
        href: '/auto-insurance/learn/difference-liability-vs-full-coverage',
      },
      {
        title: 'Unisured Motorist Coverage',
        href: '/auto-insurance/learn/uninsured-motorist-coverage',
      },
      // {
      //   title: 'Stacked Vs Unstacked Insurance',
      //   href: '/auto-insurance/learn/stacked-vs-unstacked-insurance',
      // },
      {
        title: 'Types of Auto Insurance Plans',
        href: '/types-of-auto-insurance-plans',
      },
      {
        title: 'Insurance For Leased Vehicles ',
        href: '/auto-insurance/learn/insurance-leased-car',
      },
    ],
  },
  pet: {
    guide: [
      {
        title: 'Pet Insurance 101',
        href: '/pet-insurance-explained',
      },
      {
        title: 'Pet Insurance and Pre-Existing Conditions',
        href: '/pet-insurance/learn/does-pet-insurance-cover-pre-existing-conditions',
      },
      {
        title: 'Tips To Save on Pet Care',
        href: '/pet-insurance/learn/tips-for-saving-money-on-pet-care',
      },
      {
        title: 'How To Compare Pet Insurance Plans',
        href: '/pet-insurance/learn/comparing-pet-insurance-plans',
      },
    ],
    topPicks: [
      {
        title: 'Is Pet Insurance For Dogs Worth It?',
        href: '/pet-insurance/learn/pet-insurance-for-dogs-is-it-worth-it',
      },
      {
        title: 'Is Pet Insurance For Cats Worth It?',
        href: '/pet-insurance/learn/pet-insurance-for-cats-is-it-worth-it',
      },
      {
        title: 'Can You Get Life Insurance For A Pet?',
        href: '/pet-insurance/learn/can-you-get-life-insurance-pet',
      },
      {
        title: 'What to Do When Dealing With A Pet Emergency',
        href: '/pet-insurance/learn/dealing-with-pet-emergency',
      },
    ],
  },
}

export default links
