const SvgMenuOpen = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.343 2.222a1 1 0 00-1.414 0l-.707.707a1 1 0 000 1.414L7.879 10l-5.657 5.657a1 1 0 000 1.414l.707.707a1 1 0 001.414 0L10 12.121l5.657 5.657a1 1 0 001.414 0l.707-.707a1 1 0 000-1.414L12.121 10l5.657-5.657a1 1 0 000-1.414l-.707-.707a1 1 0 00-1.414 0L10 7.879 4.343 2.222z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMenuOpen
