const SvgMenuClosed = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 1.5a1 1 0 00-1 1v1a1 1 0 001 1h17a1 1 0 001-1v-1a1 1 0 00-1-1h-17zm-1 8a1 1 0 011-1h17a1 1 0 011 1v1a1 1 0 01-1 1h-17a1 1 0 01-1-1v-1zm0 7a1 1 0 011-1h17a1 1 0 011 1v1a1 1 0 01-1 1h-17a1 1 0 01-1-1v-1z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMenuClosed
