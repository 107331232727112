import { oneOf } from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'src/styles/theme'

const variants = {
  primary: {
    color: 'white',
    hoverColor: 'white',
    background: theme.colors.action.DEFAULT,
    hoverBackground: theme.colors.action.secondary,
    border: theme.colors.action.secondary,
    hoverBorder: theme.colors.action.tertiary,
    focusShadow: '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #67D7F9',
  },
  secondary: {
    color: theme.colors.heading,
    hoverColor: 'white',
    background: 'white',
    hoverBackground: theme.colors.action.secondary,
    border: theme.colors.action.secondary,
    hoverBorder: theme.colors.action.tertiary,
    focusShadow: '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #67D7F9',
  },
  tertiary: {
    color: theme.colors.action.DEFAULT,
    hoverColor: 'white',
    background: 'white',
    hoverBackground: theme.colors.action.secondary,
    border: theme.colors.borderInput,
    hoverBorder: theme.colors.borderInput,
    focusShadow: '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #67D7F9',
  },
}

const sizes = {
  small: {
    fontSize: '12px',
    height: '32px',
    padding: '0 1rem',
  },
  medium: {
    height: '40px',
    fontSize: '14px',
    padding: '0 1rem',
  },
  large: {
    height: '48px',
    fontSize: '16px',
    padding: '0 1.5rem',
  },
}

const buttonMixin = ({
  variant,
  size,
  width = 'auto',
  selected = false,
  maxWidth = false,
}) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${variants[variant].background};
  color: ${variants[variant].color};
  text-decoration: none;
  font-weight: 600;
  font-size: ${sizes[size].fontSize};
  line-height: 16px;
  padding: ${sizes[size].padding};
  height: ${sizes[size].height};
  border-radius: 2px;
  border: 2px solid ${variants[variant].border};
  width: ${width};

  ${maxWidth &&
  `
    max-width: ${maxWidth};
    width 100%;
  `}

  ${selected && `box-shadow: ${variants[variant].focusShadow};`}

  span {
    display: contents;
  }

  &:hover {
    color: ${variants[variant].hoverColor};
    background: ${variants[variant].hoverBackground};
    border: 2px solid ${variants[variant].hoverBorder};
    cursor: pointer;
  }

  &:focus {
    box-shadow: ${variants[variant].focusShadow};
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const StyledAnchor = styled.a`
  ${buttonMixin}
`

export const StyledButton = styled.button`
  ${buttonMixin}
`

export const StyledLinkButton = styled.button`
  border: unset;
  background: none;
  outline: 0;
  color: rgb(3, 160, 206);
`

const propTypes = {
  variant: oneOf(['primary', 'secondary', 'tertiary']),
  size: oneOf(['small', 'medium', 'large']),
}

const defaultProps = {
  size: 'medium',
  variant: 'primary',
}

StyledAnchor.propTypes = propTypes
StyledAnchor.defaultProps = defaultProps

StyledButton.propTypes = propTypes

StyledButton.defaultProps = defaultProps
