import SvgHomeIcon from './Home'
import SvgLifeIcon from './Life'
import SvgMedicareIcon from './Medicare'
import SvgPetIcon from './Pet'
import SvgHealthIcon from './Health'
import SvgAutoIcon from './Auto'
import SvgSettingsIcon from './Settings'
import SvgCareersIcon from './Careers'
import SvgIdentityIcon from './Identity'
import SvgRxIcon from './Rx'

const iconsMap = new Map()
iconsMap.set('home', SvgHomeIcon)
iconsMap.set('life', SvgLifeIcon)
iconsMap.set('medicare', SvgMedicareIcon)
iconsMap.set('pet', SvgPetIcon)
iconsMap.set('health', SvgHealthIcon)
iconsMap.set('auto', SvgAutoIcon)
iconsMap.set('settings', SvgSettingsIcon)
iconsMap.set('careers', SvgCareersIcon)
iconsMap.set('identity', SvgIdentityIcon)
iconsMap.set('rx', SvgRxIcon)

export const iconList = Array.from(iconsMap.keys())

const VerticalIcon = ({ icon, ...props }) => {
  const IconComponent = iconsMap.get(icon)

  return <IconComponent {...props} />
}

export default VerticalIcon
