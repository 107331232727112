import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import lodashGet from 'lodash/get'
import uniq from 'lodash/uniq'

function SEO({
  description,
  lang,
  meta,
  title,
  properties,
  keywords,
  author,
  canonicalUrl,
}) {
  const get = (data, path, defaultValue) => {
    const value = lodashGet(data, path, defaultValue)

    if (value === null) {
      return defaultValue
    }

    return value
  }
  const site = {
    title: 'Compare insurance quotes. Insurance prices near me',
    titleTemplate: '%s | PolicyScout',
    description:
      "We've helped thousands of people find insurance quotes. PolicyScout can get you covered. Compare insurance quotes and find insurance.",
    author: 'PolicyScout',
    keywords: 'insurance, medicare, healthcare, auto, home',
    keywordsAlways: 'insurance, medicare, healthcare, auto, home', // always add these to every page
    siteUrl: 'https://www.policyscout.com',
  }

  const htmlAttributes = { lang }

  const metaTitle = title || get(site, 'siteMetadata.title', 'PolicyScout')
  const metaTitleTemplate = get(site, 'siteMetadata.titleTemplate', '%s | PolicyScout')
  const metaDescription =
    description || get(site, 'siteMetadata.description', 'PolicyScout')
  const metaKeywords = uniq(
    `${keywords || get(site, 'siteMetadata.keywords', '')} ${get(
      site,
      'siteMetadata.keywordsAlways',
      '',
    )}`.split(','),
  ).join(',')
  // const metaAuthor = author || get(site, 'siteMetadata.author', 'PolicyScout')

  const metaType = get(properties, 'type', 'website')

  const metaFacebookTitle = get(properties, 'facebook.title', metaTitle)
  const metaFacebookDescription = get(properties, 'facebook.description', metaDescription)
  const metaFacebookType = get(properties, 'facebook.type', metaType)

  const metaTwitterTitle = get(properties, 'twitter.title', metaTitle)
  const metaTwitterCard = get(properties, 'twitter.card', 'summary_large_image')
  const metaTwitterDescription = get(properties, 'twitter.description', metaDescription)
  const metaTwitterCreator = get(properties, 'twitter.author', 'policyscout')

  const metaTags = [
    {
      property: 'og:title',
      content: metaFacebookTitle,
    },
    {
      property: 'og:description',
      content: metaFacebookDescription,
    },
    {
      property: 'og:type',
      content: metaFacebookType,
    },
    {
      name: 'twitter:card',
      content: metaTwitterCard,
    },
    {
      name: 'twitter:creator',
      content: metaTwitterCreator,
    },
    {
      name: 'twitter:title',
      content: metaTwitterTitle,
    },
    {
      name: 'twitter:description',
      content: metaTwitterDescription,
    },
    ...meta,
  ]

  const link = canonicalUrl
    ? [
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ]
    : []

  return (
    <NextSeo
      htmlAttributes={htmlAttributes}
      title={metaTitle}
      titleTemplate={metaTitleTemplate}
      description={metaDescription}
      keywords={metaKeywords}
      canonical={canonicalUrl}
      meta={metaTags}
      link={link}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  properties: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

export const getSeo = (originalData, path = 'seo', defaults = {}) => {
  const data = get(originalData, path, {})

  const seo = {
    ...defaults,
    title: get(data, 'title', get(defaults, 'title', '')),
    description: get(data, 'description.description', get(defaults, 'description', '')),
    keywords: get(data, 'keywords.keywords', get(defaults, 'keywords', '')),
    canonicalUrl: get(data, 'canonicalUrl', get(defaults, 'canonicalUrl', false)),
  }

  return seo
}

//  export const query = graphql`
//    fragment seoFields on ContentfulSeo {
//      title
//      description {
//        description
//      }
//      keywords {
//        keywords
//      }
//      canonicalUrl
//    }
//  `
