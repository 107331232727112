import styled from 'styled-components'
import grabColor from 'src/utils/grabColor'

export const H1 = styled.h1`
  display: ${(props) => props.display || 'block'};
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 44px;
  color: ${grabColor('heading')};
  ${(props) => props.margin && `margin: ${props.margin}`};
`

export const H2 = styled.h2`
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  color: ${grabColor('heading')};
  margin: 0;
`

export const H3 = styled.h3`
  font-weight: bold; // 700
  font-size: 18px;
  line-height: 28px;
  color: ${grabColor('heading')};
  margin: 1.5rem 0 1rem;
`

export const H30M = styled.h3`
  font-weight: bold; // 700
  font-size: 18px;
  line-height: 28px;
  color: ${grabColor('heading')};
  margin: 0;
`

export const H2Alt = styled.span`
  display: ${(props) => props.display || 'block'};
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: ${grabColor('heading')};
`

// using span instead of h4 because of SEO
export const H4 = styled.span`
  display: ${(props) => props.display || 'block'};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${grabColor('heading')};
`

export const Navigation = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.base};
  cursor: pointer;

  &:hover {
    color: ${grabColor('detail')};
  }
`

export const NavigationNoLink = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.base};
`

export const SubHeading1 = styled.span`
  display: ${(props) => props.display || 'block'};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.base};
`

export const SubHeading2 = styled.span`
  display: ${(props) => props.display || 'block'};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.base};
`

// color on this might change
export const BodyAncillary = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${grabColor('detail')};
`

export const TextBody = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size }) => (size === 'lg' ? '18px' : '14px')};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.base};
`

export const Detail = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${grabColor('detail')};

  b,
  strong {
    font-weight: 600;
  }
`

// using span instead of h4 because of SEO
export const SectionHeading = styled.span`
  display: ${(props) => props.display || 'block'};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.heading)};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
`

export const AccountWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  min-height: 700px;
  font-family: 'Libre Franklin';
  background-color: #fafafa;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    min-height: 400px;
    margin: 0 auto;
    margin-bottom: 2em;
    justify-content: center;
  }
`
