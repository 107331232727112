import Header from './Header'
import Footer from './Footer'
import SEO from './SEO'

export default function Layout({
  children,
  verticals,
  vertical = null,
  seo = null,
  footer = {},
  header = {},
}) {
  const VerticalsSansIdentity = verticals.filter(
    (vertical) => vertical.slug !== 'identity',
  )
  return (
    <>
      {seo && <SEO {...seo} />}
      <Header verticals={VerticalsSansIdentity} vertical={vertical} {...header} />
      {children}
      <Footer verticals={VerticalsSansIdentity} vertical={vertical} {...footer} />
    </>
  )
}
