import { useId } from 'react'

function SvgLifeIcon(props) {
  const uniqueMaskID = useId()
  return (
    <svg
      width={30}
      height={34}
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.678 6.738c-.669.118-1.207.64-1.269 1.316-.07.772-.106 1.555-.106 2.346 0 9.135 4.801 17.126 11.97 21.488a1.399 1.399 0 001.455 0c7.168-4.362 11.97-12.353 11.97-21.488 0-.785-.036-1.561-.106-2.328-.061-.679-.603-1.202-1.275-1.317a24.121 24.121 0 01-10.39-4.48 1.636 1.636 0 00-1.954 0A24.124 24.124 0 013.678 6.738z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M3.548 6c-.96.168-1.79.931-1.886 1.985-.072.796-.11 1.601-.11 2.415 0 9.403 4.944 17.634 12.33 22.128a2.149 2.149 0 002.236 0c7.386-4.494 12.33-12.725 12.33-22.127 0-.808-.037-1.608-.109-2.397-.095-1.058-.93-1.823-1.895-1.988a23.37 23.37 0 01-10.067-4.342 2.386 2.386 0 00-2.854 0A23.374 23.374 0 013.548 6z"
        stroke="#7C3AED"
        strokeWidth={1.5}
      />
      <mask
        id={`a-life-icon-mask-${uniqueMaskID}`}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={1}
        width={26}
        height={32}
      >
        <path
          d="M3.678 6.738c-.669.118-1.207.64-1.269 1.316-.07.772-.106 1.555-.106 2.346 0 9.135 4.801 17.126 11.97 21.488a1.399 1.399 0 001.455 0c7.168-4.362 11.97-12.353 11.97-21.488 0-.785-.036-1.561-.106-2.328-.061-.679-.603-1.202-1.275-1.317a24.121 24.121 0 01-10.39-4.48 1.636 1.636 0 00-1.954 0A24.124 24.124 0 013.678 6.738z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#a-life-icon-mask-${uniqueMaskID})`} fill="#7C3AED">
        <path
          opacity={0.05}
          d="M3.678 6.738c-.669.118-1.207.64-1.269 1.316-.07.772-.106 1.555-.106 2.346 0 9.135 4.801 17.126 11.97 21.488a1.399 1.399 0 001.455 0c7.168-4.362 11.97-12.353 11.97-21.488 0-.785-.036-1.561-.106-2.328-.061-.679-.603-1.202-1.275-1.317a24.121 24.121 0 01-10.39-4.48 1.636 1.636 0 00-1.954 0A24.124 24.124 0 013.678 6.738z"
        />
        <ellipse opacity={0.8} cx={18.5184} cy={7} rx={12.0135} ry={12} />
      </g>
    </svg>
  )
}

export default SvgLifeIcon
