function SvgCheckSquare(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 14a1.5 1.5 0 001.5-1.5v-11c0-.813-.688-1.5-1.5-1.5h-11A1.5 1.5 0 000 1.5v11c0 .844.656 1.5 1.5 1.5h11zm-6.406-3.063a.476.476 0 01-.719 0l-3.25-3.25a.49.49 0 010-.687l.719-.719a.49.49 0 01.687 0L5.75 8.47l4.688-4.688a.49.49 0 01.687 0l.719.719a.49.49 0 010 .688l-5.75 5.75z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheckSquare
