import { Amplify, Auth } from 'aws-amplify'

const SignOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    return error
  }
}

export default SignOut
