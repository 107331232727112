import Image from "next/legacy/image";
import Link from 'next/link'
import styled from 'styled-components'
import Container from 'src/styles/Container'
import { H3, H2, SectionHeading, Detail, TextBody } from 'src/styles/Typography'
import VerticalIcon from 'src/components/icons/vertical'
import SvgMedal from 'src/components/icons/Medal'
import SvgChevron from 'src/components/icons/Chevron'
import SvgCalculator from 'src/components/icons/Calculator'
import SvgCheckSquare from 'src/components/icons/CheckSquare'
import SvgMapSigns from 'src/components/icons/MapSigns'
import grabColor from 'src/utils/grabColor'
import SvgRxIcon from 'src/components/icons/RxIcon'
import { StyledAnchor } from 'src/styles/Button'

import links from './links'

function FlyoutNav({ vertical, mouseEnter, mouseLeave }) {
  const verticalSlug = vertical?.slug?.replace('-insurance', '')
  const verticalUrlSlug = vertical?.slug

  return (
    <FlyoutNavWrapper onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <FlyoutContainer verticalSlug={verticalSlug}>
        <Container>
          <FlyoutInner>
            <Left>
              <MainImageWrapper>
                {vertical?.hero?.photo && (
                  <Image
                    src={vertical.hero.photo.url}
                    quality={30}
                    alt={vertical?.hero?.photo?.description || ''}
                    layout="fill"
                    priority
                  />
                )}
              </MainImageWrapper>
              <StyledH3 margin="15px 0 0">{vertical.title}</StyledH3>
              <StyledDetail>{vertical.flyoutNavigationTagline}</StyledDetail>
            </Left>
            <Right>
              <RightTop>
                <H2>
                  <IconWrapper>
                    <VerticalIcon icon={verticalSlug} />
                  </IconWrapper>
                  {vertical.title}
                </H2>
                <Link href={`/${verticalUrlSlug}/best`} passHref alt="Best Of" legacyBehavior>
                  <BestHealthInsurance>
                    <StyledH3>
                      <IconStyle verticalSlug={verticalSlug}>
                        <SvgMedal />
                      </IconStyle>{' '}
                      {new Date().getFullYear()} Best {vertical.title}
                    </StyledH3>
                    <StyledSvgChevron />
                  </BestHealthInsurance>
                </Link>
              </RightTop>
              <RightBottom verticalSlug={verticalSlug}>
                <RightBottomInner>
                  <SectionHeading color="detail">
                    <IconStyle verticalSlug={verticalSlug}>
                      <SvgMapSigns />
                    </IconStyle>
                    Guides
                  </SectionHeading>

                  <UL>
                    {links[verticalSlug]?.guide?.map((link, index) => (
                      <li key={`${verticalSlug}-guide-${index}`}>
                        <Link href={link.href} legacyBehavior>{link.title}</Link>
                      </li>
                    ))}
                  </UL>
                  <More>
                    <Link href={`/${verticalUrlSlug}/learn`}>More Guides</Link>
                  </More>
                </RightBottomInner>
                <RightBottomInner>
                  <SectionHeading color="detail">
                    <IconStyle verticalSlug={verticalSlug}>
                      <SvgCheckSquare />
                    </IconStyle>
                    Our Top Picks
                  </SectionHeading>
                  <UL>
                    {links[verticalSlug]?.topPicks?.map((link, index) => (
                      <li key={`${verticalSlug}-topPicks-${index}`}>
                        <Link href={link.href} legacyBehavior>{link.title}</Link>
                      </li>
                    ))}
                  </UL>
                  <More>
                    <Link href={`/${verticalUrlSlug}/best`}>More Picks</Link>
                  </More>
                </RightBottomInner>
                {(verticalSlug === 'health' || verticalSlug === 'medicare') && (
                  <RxCardCallout>
                    <RxIconWrapper>
                      <SvgRxIcon />
                    </RxIconWrapper>
                    <H3>Rx Discount Card</H3>
                    <TextBody>Save up to $95 on each Rx</TextBody>
                    <Link href="/rx/download" legacyBehavior>
                      <StyledAnchor variant="tertiary" width="100%">
                        Get Your Free Card
                      </StyledAnchor>
                    </Link>
                  </RxCardCallout>
                )}
                {/* <RightBottomInner>
                  <SectionHeading color="detail">
                    <IconStyle verticalSlug={verticalSlug}>
                      <SvgCalculator />
                    </IconStyle>
                    Calculators
                  </SectionHeading>
                  <UL>
                    <li>
                      <Link href="/">How much do I really need?</Link>
                    </li>
                    <li>
                      <Link href="/">How much will it cost?</Link>
                    </li>
                  </UL>
                </RightBottomInner> */}
              </RightBottom>
            </Right>
          </FlyoutInner>
        </Container>
      </FlyoutContainer>
    </FlyoutNavWrapper>
  );
}

export default FlyoutNav

const RxIconWrapper = styled.div`
  svg {
    width: 48px;
    height: 48px;
  }
`

const RxCardCallout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;

  width: 258px;
  height: 218px;

  background: ${grabColor('backgroundLight')};
  border-radius: 4px;

  ${H3} {
    margin: 0;
    color: ${grabColor('rx.DEFAULT')};
  }

  ${TextBody} {
    margin: 0;
  }

  a {
    margin-top: 8px;
    margin-bottom: -8px;
  }
`

const RightBottomInner = styled.div`
  display: flex;
  flex-direction: column;
  ${SectionHeading} {
    height: 20px;
  }
`

const More = styled.div`
  padding: 8px 0 0 0;
  a {
    font-size: 14px;
    color: ${(props) => props.theme.colors.base};

    &:hover {
      color: ${(props) => props.theme.colors.detail};
    }
  }
`

const StyledSvgChevron = styled(SvgChevron)`
  color: ${grabColor('accentMid')};
`

const BestHealthInsurance = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  > * + * {
    margin-left: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
`

const IconStyle = styled.span`
  display: inline-block;
  margin: 0 12px 0 0;
  color: ${({ verticalSlug, theme }) =>
    verticalSlug ? theme.colors[verticalSlug].DEFAULT : ''};
  vertical-align: middle;
`

const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 18px;
`

const RightTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const Right = styled.div`
  padding: 0 0 0 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RightBottom = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: 1fr 1fr;
  grid-template-columns: ${({ verticalSlug }) =>
    verticalSlug === 'health' || verticalSlug === 'medicare'
      ? '1fr 1fr 258px'
      : '1fr 1fr'};
  grid-gap: 1rem 2.5rem;
  margin: 2.4rem 0 2px;
`

const UL = styled.ul`
  flex: 1;
  margin: 1rem 0 0 0;
  list-style: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.base};
  font-size: 14px;
  line-height: 24px;

  li {
    margin: 0 0 8px 0;
    a {
      color: ${({ theme }) => theme.colors.base};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.borderContent};
  padding: 0 0 2px;
`

const StyledDetail = styled(Detail)`
  margin: 3px 0 0;
  line-height: 1.3;
`

const StyledH3 = styled(H3)`
  margin: ${({ margin }) => margin || '0'};
`

export const FlyoutNavWrapper = styled.div`
  position: absolute;
  left: 0;
  top: calc(92px - 1.5rem);
  width: 100%;
  display: none;
  z-index: 200;

  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.06));

  &:hover {
    display: block;
    color: ${({ theme }) => theme.colors.base};
    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: none;
    }
  }
`

const FlyoutContainer = styled.div`
  padding: 2.5rem 1.5rem;
  margin: 1.5rem 0 0 0;
  background: white;
  border-top: 3px solid
    ${({ verticalSlug, theme }) =>
      verticalSlug ? theme.colors[verticalSlug].DEFAULT : 'transparent'};
`

const FlyoutInner = styled.div`
  display: grid;
  grid-template-columns: calc(208px + 2rem) 800px;
  margin: 0 auto;
  justify-content: center;
`

const MainImageWrapper = styled.div`
  flex: 1;
  position: relative;
  margin: 0;
  width: 208px;

  img {
    object-fit: cover;
  }
`
