import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { getAlgoliaResults } from '@algolia/autocomplete-js'

import algoliasearch from 'algoliasearch'

import SvgMenuOpen from 'src/components/icons/MenuOpen'
import grabColor from 'src/utils/grabColor'

// import Autocomplete from './Autocomplete'
const Autocomplete = dynamic(() => import('src/components/Search/Autocomplete'), {
  ssr: false,
})
import { ItemSectionHeading } from './Results/Components'
import Vertical from './Results/Vertical'
import VerticalProvider from './Results/VerticalProvider'
import Article from './Results/Article'

import '@algolia/autocomplete-theme-classic'

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_KEY,
)

// const querySuggestionsPlugin = createQuerySuggestionsPlugin({
//   searchClient,
//   indexName: 'article_query_suggestions',
// })

const createUrl = (item, type) => {
  if ('article' === type) {
    return `/${item.vertical_slug}/learn/${item.slug}`
  }

  if ('verticalProvider' === type) {
    return `/${item.vertical_slug}/reviews/${item.slug}`
  }

  return `/${item.slug || ''}`
}

const AlgoliaSearch = () => {
  const getSources = ({ query }) => {
    if (!query) {
      return [
        {
          sourceId: 'articles',
          getItemInputValue({ item }) {
            return item.title
          },
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: 'article',
                  query,
                },
              ],
            })
          },
          getItemUrl({ item }) {
            return createUrl(item, 'article')
          },
          templates: {
            header() {
              return <ItemSectionHeading>Suggested Articles</ItemSectionHeading>
            },
            item({ item, components }) {
              return (
                <Article
                  hit={item}
                  components={components}
                  url={createUrl(item, 'article')}
                />
              )
            },
          },
        },
      ]
    }

    return [
      {
        sourceId: 'articles',
        getItemInputValue({ item }) {
          return item.title
        },
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: 'article',
                query,
              },
            ],
          })
        },
        getItemUrl({ item }) {
          return createUrl(item, 'article')
        },
        templates: {
          header() {
            return <ItemSectionHeading>Articles</ItemSectionHeading>
          },
          item({ item, components }) {
            return (
              <Article
                hit={item}
                components={components}
                url={createUrl(item, 'article')}
              />
            )
          },
        },
      },
      {
        sourceId: 'verticalProvider',
        getItemInputValue({ item }) {
          return item.title
        },
        getItemUrl({ item }) {
          return createUrl(item, 'verticalProvider')
        },
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: 'verticalProvider',
                query,
              },
            ],
          })
        },
        templates: {
          header() {
            return <ItemSectionHeading>Vertical Providers</ItemSectionHeading>
          },
          item({ item, components }) {
            return (
              <VerticalProvider
                hit={item}
                components={components}
                url={createUrl(item, 'verticalProvider')}
              />
            )
          },
        },
      },
      {
        sourceId: 'vertical',
        getItemInputValue({ item }) {
          return item.title
        },
        getItemUrl({ item }) {
          return createUrl(item, 'vertical')
        },
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: 'vertical',
                query,
              },
            ],
          })
        },
        templates: {
          header() {
            return <ItemSectionHeading>Verticals</ItemSectionHeading>
          },
          item({ item, components }) {
            return (
              <Vertical
                hit={item}
                components={components}
                url={createUrl(item, 'vertical')}
              />
            )
          },
        },
      },
    ]
  }

  return (
    <Autocomplete
      placeholder="Search for the things!"
      openOnFocus={true}
      defaultActiveItemId={0}
      getSources={getSources}
      // plugins={[querySuggestionsPlugin]}
    />
  )
}

export const SearchBar = ({ className, desktopSearch }) => {
  return (
    <div className={className}>
      <AlgoliaSearch />
      {desktopSearch.show && (
        <CloseIcon onClick={desktopSearch.toggle} aria-label="Close Search">
          <SvgMenuOpen width="16" height="16" />
        </CloseIcon>
      )}
    </div>
  )
}

const CloseIcon = styled.button`
  border: 0;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  padding: 1em;

  svg {
    font-size: 90%;
    color: ${grabColor('detail')};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.error.DEFAULT};
    }
  }
`

export const SearchBarStyled = styled(SearchBar)`
  background-color: white;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  ${CloseIcon} {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: block;
    }
  }
`

export default SearchBarStyled
