import { useId } from 'react'

function SvgRxIcon(props) {
  const id = useId()
  return (
    <svg
      width={73}
      height={72}
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id={id}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={8}
        y={3}
        width={56}
        height={66}
      >
        <path
          d="M11.653 13.661c-1.453.255-2.622 1.388-2.756 2.857a56.103 56.103 0 00-.232 5.099c0 19.845 10.431 37.205 26.004 46.68a3.04 3.04 0 003.162 0c15.572-9.475 26.004-26.835 26.004-46.68 0-1.706-.078-3.393-.228-5.06-.134-1.474-1.31-2.61-2.77-2.86-8.342-1.428-16.025-4.836-22.571-9.733a3.554 3.554 0 00-4.248 0c-6.492 4.857-14.103 8.249-22.365 9.697z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path
          d="M11.653 13.661c-1.453.255-2.622 1.388-2.756 2.857a56.103 56.103 0 00-.232 5.099c0 19.845 10.431 37.205 26.004 46.68a3.04 3.04 0 003.162 0c15.572-9.475 26.004-26.835 26.004-46.68 0-1.706-.078-3.393-.228-5.06-.134-1.474-1.31-2.61-2.77-2.86-8.342-1.428-16.025-4.836-22.571-9.733a3.554 3.554 0 00-4.248 0c-6.492 4.857-14.103 8.249-22.365 9.697z"
          fill="#fff"
        />
        <path
          opacity={0.7}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 15.6H18.25v52.636h6.843V40.491h6.264l7.7 7.797-8.426 8.531 4.828 4.888 8.425-8.53 8.425 8.53 4.828-4.888-8.425-8.53 8.425-8.53-4.828-4.888-8.425 8.53-3.949-4a12.412 12.412 0 007.37-11.355C47.305 21.196 41.8 15.6 35 15.6zm-9.908 17.993V22.498H35c2.997 0 5.464 2.476 5.464 5.548 0 3.071-2.467 5.547-5.464 5.547h-9.907z"
          fill="#24A850"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.407 21.617c0-1.819.082-3.618.245-5.395.283-3.11 2.718-5.294 5.438-5.77 7.742-1.358 14.88-4.537 20.975-9.098a6.812 6.812 0 018.153 0c6.147 4.6 13.352 7.793 21.169 9.13 2.732.469 5.183 2.657 5.465 5.78.16 1.763.241 3.549.241 5.353 0 21.009-11.046 39.41-27.568 49.465a6.298 6.298 0 01-6.55 0C16.453 61.028 5.407 42.626 5.407 21.617zm3.49-5.099c.134-1.469 1.303-2.602 2.756-2.857 8.262-1.448 15.873-4.84 22.365-9.698a3.554 3.554 0 014.248 0c6.546 4.898 14.23 8.306 22.57 9.734 1.46.25 2.637 1.386 2.77 2.86.151 1.667.229 3.354.229 5.06 0 19.845-10.431 37.205-26.004 46.68a3.04 3.04 0 01-3.162 0C19.096 58.823 8.665 41.463 8.665 21.618c0-1.72.079-3.42.232-5.099z"
        fill="#24A850"
      />
    </svg>
  )
}

export default SvgRxIcon
