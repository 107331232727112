import Image from "next/legacy/image";
import styled from 'styled-components'

import { ImageHolder, Content } from './Components'

export const ArticleRaw = ({ hit, components, url, className = '' }) => {
  const desc = hit?.shortDescription
  const description = desc?.length > 189 ? `${desc.substring?.(0, 190)}...` : desc

  return (
    <a className={className} href={url}>
      <Content>
        <ImageHolder height="100px">
          {hit?.asset_file ? (
            <Image src={`http:${hit.asset_file}`} layout="fill" alt="Article photo" />
          ) : (
            <span />
          )}
        </ImageHolder>

        <Content>
          <h4>
            <components.Highlight hit={hit} attribute="title" />
          </h4>
          <p>{description}</p>
        </Content>
      </Content>
    </a>
  )
}

export const Article = styled(ArticleRaw)`
  display: block;
  text-decoration: none;
  color: black;
  padding: 0.5em;

  > ${Content} {
    display: flex;
    width: 100%;

    ${ImageHolder} {
      flex: 1;
    }

    ${Content} {
      flex: 3;
      padding-left: 1em;

      h4 {
        margin: 0;
        padding: 0.5em 0 1em 0;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
`

export default Article
