import styled from 'styled-components'

export const VerticalProviderRaw = ({ hit, components, url, className }) => {
  return (
    <a className={className} href={url}>
      <h4>
        <components.Highlight hit={hit} attribute="title" />
      </h4>
    </a>
  )
}

export const VerticalProvider = styled(VerticalProviderRaw)`
  display: block;
  text-decoration: none;
  color: black;
  padding: 0.25em 0.5em;
`

export default VerticalProvider
