const SvgInstagram = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="icon-instagram-a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={25}
      height={24}
    >
      <path d="M.667.008h23.992v23.99H.667V.007z" fill="#fff" />
    </mask>
    <g mask="url(#icon-instagram-a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.663.008c-3.258 0-3.667.014-4.946.072C6.44.138 5.568.341 4.805.638A5.88 5.88 0 002.68 2.02a5.88 5.88 0 00-1.383 2.125C1 4.909.797 5.781.739 7.058.68 8.338.667 8.746.667 12.004s.014 3.667.072 4.946c.058 1.277.261 2.149.558 2.912a5.88 5.88 0 001.383 2.125 5.881 5.881 0 002.125 1.384c.763.296 1.635.499 2.912.557 1.28.058 1.688.072 4.946.072s3.666-.014 4.946-.072c1.277-.058 2.149-.261 2.912-.558a5.88 5.88 0 002.125-1.383 5.88 5.88 0 001.383-2.125c.297-.763.5-1.635.558-2.912.058-1.28.072-1.688.072-4.946s-.014-3.666-.072-4.946c-.058-1.277-.261-2.149-.558-2.912a5.88 5.88 0 00-1.383-2.125A5.88 5.88 0 0020.52.638c-.763-.297-1.635-.5-2.912-.558-1.28-.058-1.688-.072-4.946-.072zm0 2.161c3.203 0 3.582.013 4.848.07 1.17.054 1.804.249 2.227.413.56.218.96.478 1.38.898.42.42.68.819.897 1.379.164.423.36 1.058.413 2.228.057 1.264.07 1.644.07 4.847 0 3.203-.013 3.583-.07 4.848-.054 1.17-.249 1.804-.413 2.227-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.379.897-.423.164-1.058.36-2.227.413-1.265.058-1.645.07-4.848.07s-3.583-.012-4.848-.07c-1.17-.053-1.804-.249-2.227-.413a3.718 3.718 0 01-1.38-.898c-.42-.42-.68-.819-.897-1.379-.164-.423-.36-1.058-.413-2.227-.058-1.265-.07-1.645-.07-4.848s.012-3.583.07-4.847c.054-1.17.249-1.805.413-2.228.218-.56.478-.96.898-1.38.42-.42.819-.68 1.379-.897.423-.164 1.058-.36 2.227-.413 1.265-.057 1.645-.07 4.848-.07z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.663 16.003a3.999 3.999 0 110-7.998 3.999 3.999 0 010 7.998zm0-10.16a6.16 6.16 0 100 12.321 6.16 6.16 0 000-12.32zM20.506 5.6a1.44 1.44 0 11-2.879 0 1.44 1.44 0 012.88 0z"
      fill="currentColor"
    />
  </svg>
)

export default SvgInstagram
