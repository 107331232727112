import styled from 'styled-components'

import { theme } from 'src/styles/theme'

export const ItemSectionHeading = styled.h4`
  color: ${theme.colors.heading};
  padding-left: 1.2em;
`

export const HitLink = styled.a`
  display: ${(props) => props?.display ?? 'flex'};
  flex-direction: ${(props) => props?.flexDirection ?? 'row'};
`

export const ImageHolder = styled.div`
  width: ${(props) => props?.width ?? '150px'};
  height: ${(props) => props?.height ?? '150px'};
  position: relative;

  img {
    object-fit: cover;
  }
`

export const Description = styled.p``

export const Content = styled.div``
