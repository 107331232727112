import styled from 'styled-components'
import SvgSearch from 'src/components/icons/Search'
import grabColor from 'src/utils/grabColor'

export const Search = ({ className, desktopSearch }) => {
  return (
    <div className={className}>
      <SvgSearch onClick={desktopSearch.toggle} aria-label="Toggle Search" />
    </div>
  )
}

export const SearchStyled = styled(Search)`
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px inset transparent;
  font-size: 16px; // This needs to be 16 to prevent zoom on mobile
  line-height: 24px;
  color: ${grabColor('detail')};

  input {
    display: none;
    background: ${grabColor('backgroundLight')};
    width: 100%;
    margin-left: 1rem;
    border: unset;
    outline: unset;

    &::placeholder {
      font-family: Libre Franklin;
      font-size: 14px;
      line-height: 24px;
      color: ${grabColor('detail')};
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 1rem;
    }
  }

  svg {
    color: ${grabColor('heading')};
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      color: ${({ theme }) => theme.colors.accentMid};
    }
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.brand.DEFAULT};
    }
  }

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.brand.DEFAULT};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0;
    margin-bottom: 24px;
    background: ${grabColor('backgroundLight')};
    border-radius: 2px;
    height: 42px;
    padding-left: 1rem;

    input {
      display: block;
    }
  }
`

export default SearchStyled
