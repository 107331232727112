import { useId } from 'react'

function SvgRxIcon(props) {
  const uniqueMaskID = useId()
  return (
    <svg
      width={25}
      height={28}
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.609 5.79c-.535.094-.966.512-1.015 1.053a20.659 20.659 0 00-.085 1.877c0 7.308 3.841 13.7 9.576 17.19a1.12 1.12 0 001.164 0c5.734-3.49 9.575-9.882 9.575-17.19 0-.628-.028-1.25-.084-1.863-.049-.543-.482-.961-1.02-1.053a19.297 19.297 0 01-8.311-3.585 1.308 1.308 0 00-1.564 0 19.3 19.3 0 01-8.236 3.572z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M1.847 6.774A21.41 21.41 0 001.76 8.72c0 7.576 3.983 14.208 9.936 17.83a1.87 1.87 0 001.944 0c5.953-3.622 9.935-10.254 9.935-17.83 0-.65-.029-1.295-.087-1.93-.083-.923-.81-1.583-1.64-1.726a18.547 18.547 0 01-7.989-3.445 2.059 2.059 0 00-2.463 0A18.55 18.55 0 013.48 5.052c-.826.145-1.548.804-1.632 1.723z"
        stroke="#24A850"
        strokeWidth={1.5}
      />
      <mask
        id={uniqueMaskID}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={1}
        width={21}
        height={26}
      >
        <path
          d="M3.609 5.79c-.535.094-.966.512-1.015 1.053a20.659 20.659 0 00-.085 1.877c0 7.308 3.841 13.7 9.576 17.19a1.12 1.12 0 001.164 0c5.734-3.49 9.575-9.882 9.575-17.19 0-.628-.028-1.25-.084-1.863-.049-.543-.482-.961-1.02-1.053a19.297 19.297 0 01-8.311-3.585 1.308 1.308 0 00-1.564 0 19.3 19.3 0 01-8.236 3.572z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${uniqueMaskID})`} fill="#24A850">
        <path
          opacity={0.05}
          d="M3.609 5.79c-.535.094-.966.512-1.015 1.053a20.659 20.659 0 00-.085 1.877c0 7.308 3.841 13.7 9.576 17.19a1.12 1.12 0 001.164 0c5.734-3.49 9.575-9.882 9.575-17.19 0-.628-.028-1.25-.084-1.863-.049-.543-.482-.961-1.02-1.053a19.297 19.297 0 01-8.311-3.585 1.308 1.308 0 00-1.564 0 19.3 19.3 0 01-8.236 3.572z"
        />
        <ellipse opacity={0.7} cx={5.0776} cy={18.8002} rx={9.6108} ry={9.6} />
        <ellipse opacity={0.3} cx={20.2861} cy={15.6} rx={9.6108} ry={9.6} />
      </g>
    </svg>
  )
}

export default SvgRxIcon
