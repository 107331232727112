import Link from 'next/link'
import styled from 'styled-components'
import Container from 'src/styles/Container'
import { FooterLogo } from 'src/components/icons/Logo'
import {
  H4,
  Navigation,
  NavigationNoLink,
  SubHeading2,
  Detail,
  BodyAncillary,
} from 'src/styles/Typography'
import SocialMedia from 'src/components/icons/social'

const Footer = ({
  verticals,
  vertical = null,
  noBorder = false,
  apply = false,
  LP = false,
  TY = false,
  rx = false,
  federalContractingStatement = true,
  TMPO = true,
  SMID = 'MULTIPLAN_2023XDL195_M',
  overridePhoneNumber = '1-888-912-2132',
}) => {
  const verticalSlug = vertical?.slug?.replace('-insurance', '')

  return (
    <FooterWrapper verticalSlug={verticalSlug} noBorder={noBorder}>
      <Container>
        <FooterTop apply={apply}>
          <div>
            <FooterLogo />
            <LogoSubHeading2 display="block">
              Making insurance <br />
              work for you.
            </LogoSubHeading2>
          </div>

          <div>
            <H4>24/7 On-Demand Help</H4>
            <UL>
              <li>
                <Navigation href="mailto:help@policyscout.com">
                  help@policyscout.com
                </Navigation>
              </li>
              <li>
                <SocialMedia />
              </li>
            </UL>
          </div>
          <div>
            <H4>
              {LP
                ? `Call to connect to a licensed insurance agent`
                : `Professional Advice, Free`}
            </H4>
            <UL>
              <li>
                <Navigation href={`tel:${overridePhoneNumber}`}>
                  {overridePhoneNumber}
                </Navigation>
                <span> TTY: 711</span>
              </li>
              <li>
                <NavigationNoLink>Monday - Friday</NavigationNoLink>
              </li>
              <li>
                <NavigationNoLink>9:00am - 7:00pm ET</NavigationNoLink>
              </li>
            </UL>
          </div>
          {!apply && (
            <div>
              <H4>Find Your Best Insurance</H4>
              <VerticalsList>
                {verticals.map((vertical) => {
                  return (
                    <li key={vertical.slug}>
                      <Link href={`/${vertical.slug}`} passHref legacyBehavior>
                        <Navigation>
                          {vertical.title.replace(' Insurance', '')}
                        </Navigation>
                      </Link>
                    </li>
                  )
                })}
              </VerticalsList>
            </div>
          )}
        </FooterTop>
        <FooterBottom>
          <div>
            <BodyAncillary>
              Throughout the years, PolicyScout&trade; has developed relationships with
              leading insurance companies to provide the best rates for consumers. We
              pride ourselves on putting the consumers first and delivering a new way to
              shop for insurance online. With our platform, consumers can compare all
              types of insurance types. From Medicare, Health Insurance, Life Insurance,
              Auto Insurance, Home Insurance and more. By using our platform, we’ll
              protect your data, find you savings and even help you enroll for insurance
              online. We didn’t invent comparing insurance. We just perfected it.
            </BodyAncillary>
          </div>
          <div>
            <UL>
              <li>
                <Link href="/privacy" passHref legacyBehavior>
                  <Navigation>Security & Privacy</Navigation>
                </Link>
              </li>
              <li>
                <Link href="/terms" passHref legacyBehavior>
                  <Navigation>Terms of Service</Navigation>
                </Link>
              </li>
              {rx && (
                <>
                  <li>
                    <Link href="/rx-terms-and-conditions-of-use" passHref legacyBehavior>
                      <Navigation>RX Terms & Conditions</Navigation>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/policy-scout-program-description"
                      passHref
                      legacyBehavior
                    >
                      <Navigation>RX Program Description</Navigation>
                    </Link>
                  </li>
                </>
              )}
              {!apply && !LP && !TY && (
                <>
                  <li>
                    <Link href="/careers" passHref legacyBehavior>
                      <Navigation>PolicyScout Careers</Navigation>
                    </Link>
                  </li>
                  <li>
                    <Link href="/careers-agents" passHref legacyBehavior>
                      <Navigation>Become an Agent</Navigation>
                    </Link>
                  </li>
                </>
              )}
            </UL>
          </div>
          <Detail>
            All Rights Reserved by PolicyScout &copy; {new Date().getFullYear()}
          </Detail>
        </FooterBottom>
        <BottomBottom>
          {TMPO && (
            <Detail>
              We do not offer every plan available in your area. Currently we represent 35
              organizations which offer 68,930 products in your area. Please contact
              Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program
              to get information on all of your options.
            </Detail>
          )}
          {federalContractingStatement && (
            <Detail>
              Plans are insured or covered by a Medicare Advantage organization with a
              Medicare contract and/or a Medicare-approved HMO, PPO, SNP, PFFS, PDP
              sponsor. Enrollment in the plan depends on the plan&rsquo;s contract renewal
              with Medicare.
            </Detail>
          )}
          {TMPO && (
            <Detail>
              To send a complaint to Medicare, call 1-800-MEDICARE (TTY users should call
              1- 877-486-2048), 24 hours a day/7 days a week. If your complaint involves a
              broker or agent, be sure to include the name of the person when filing your
              grievance.
            </Detail>
          )}
          {SMID && <Detail className="center">{SMID}</Detail>}
        </BottomBottom>
        {rx && (
          <FooterRx>
            <Detail>Rx Discount Card Details</Detail>
            <FooterRxContent>
              <div>
                <Detail>
                  * Prescription savings vary by prescription and by pharmacy, and may The
                  range of prescription discounts provided under this discount program
                  will vary depending on the prescription and pharmacy where the
                  prescription is purchased and can be up to $95 off the retail price.
                </Detail>
                <Detail>
                  <strong>The PolicyScout Rx Discount Card is NOT insurance.</strong> It
                  is also not a Medicare prescription drug plan.
                </Detail>
                <Detail>
                  The range of prescription discounts provided under this discount program
                  will vary depending on the prescription and pharmacy where the
                  prescription is purchased and can be up to $95 off the retail price.
                </Detail>
              </div>
              <div>
                <Detail>
                  You are fully responsible for paying your prescriptions at the pharmacy
                  at the time of service, but you will be entitled to receive a discount
                  from the pharmacy in accordance with the specific pre-negotiated
                  discounted rate schedule.
                </Detail>
                <Detail>
                  For additional information, including an up-to-date list of pharmacies
                  and covered prescriptions, or assistance with any problems related to
                  this prescription drug discount plan, please contact our member support
                  line toll free at (800) 999-3053 or visit policyscout.com/rx.
                </Detail>
              </div>
            </FooterRxContent>
          </FooterRx>
        )}
      </Container>
    </FooterWrapper>
  )
}

const BottomBottom = styled.div`
  .center {
    text-align: center;
  }
`

const FooterRx = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderContent};
  padding: 1.5rem 0 3rem;
  margin-top: 1.5rem;
`

const FooterRxContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 3rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
  }
`

const FooterWrapper = styled.footer`
  ${({ noBorder, verticalSlug, theme }) =>
    !noBorder &&
    verticalSlug &&
    `border-top: 6px solid ${theme.colors[verticalSlug].DEFAULT};`};
  padding-bottom: 1.5rem;

  ${Navigation} + span {
    font-size: 13px;
    line-height: 24px;
    padding-left: 0.4rem;
  }
`

const UL = styled.ul`
  list-style-type: none;
  padding: 0;

  li + li {
    margin-top: 0.5rem;
  }
`

const VerticalsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style-type: none;
  padding: 0;
  gap: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
    flex-wrap: wrap;
  }
`

const LogoSubHeading2 = styled(SubHeading2)`
  max-width: 220px;
  margin: 3px 0 0;
`

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4.5rem 0 3rem;

  & ${H4} {
    margin: 0 0 1rem;
  }

  // add css if apply is true
  ${({ apply }) =>
    apply &&
    `
    margin-right: 30%;
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    margin-right: initial;
    padding: 3rem 0 2.5rem;
  }
`

const FooterBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 220px;
  gap: 0 4rem;
  border-top: 1px solid ${({ theme }) => theme.colors.borderContent};
  padding: 18px 0 3rem;

  p {
    margin-bottom: 0px;
  }

  & ${Detail} {
    margin-top: 1rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
  }
`

export default Footer
