import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 1.5rem 0;
  max-width: calc(1240px + 3rem);
  margin: 0 auto;
  width: 100%;
`

export const ContainerSmall = styled.div`
  padding: 0 1.5rem 0;
  max-width: calc(1040px + 3rem);
  margin: 0 auto;
  width: 100%;
`

export const ContainerExtraSmall = styled.div`
  padding: 0 1.5rem 0;
  max-width: calc(800px + 3rem);
  margin: 0 auto;
  width: 100%;
`

export const ApplyBContainerExtraSmall = styled.div`
  padding: 0 1.5rem 0;
  max-width: calc(560px + 3rem);
  margin: 0 auto;
  width: 100%;
`

export const Box = styled.div``

export default Container
