import { useState, Fragment } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import grabColor from 'src/utils/grabColor'
import { SectionHeading } from 'src/styles/Typography'
import { StyledAnchor, StyledButton } from 'src/styles/Button'
import VerticalIcon from 'src/components/icons/vertical'

import FlyoutNav, { FlyoutNavWrapper } from './FlyoutNav'
import SvgPointer, { StyledSVG } from './SvgPointer'

import SearchBar from '../Search/SearchBar'

const ignoreFlyoutHoverList = ['settings', 'identity']

const VerticalAnchor = styled.a`
  position: relative;
  text-decoration: none;
  pointer-events: ${({ disableLink }) => (disableLink ? 'none' : 'auto')};
  padding: 0 1rem;
  margin: 0 -1rem;
  z-index: 15;
  &:hover,
  &.hovered {
    background: ${({ color, theme, verticalSlug }) =>
      ignoreFlyoutHoverList.includes(verticalSlug)
        ? '#ffffff'
        : theme.colors[color].DEFAULT};
    color: ${({ color, theme, verticalSlug }) =>
      ignoreFlyoutHoverList.includes(verticalSlug)
        ? theme.colors[color].DEFAULT
        : 'white'};
    span {
      color: ${({ color, theme, verticalSlug }) =>
        ignoreFlyoutHoverList.includes(verticalSlug)
          ? theme.colors[color].DEFAULT
          : 'white'};
    }

    span.nav-item-inner {
      border-bottom: 3px solid
        ${({ color, theme, verticalSlug }) => theme.colors[color].DEFAULT};
    }

    span ${StyledSVG} {
      display: ${({ verticalSlug }) =>
        ignoreFlyoutHoverList.includes(verticalSlug) ? 'none' : 'block'};
      @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: none;
      }
    }

    & + ${FlyoutNavWrapper} {
      display: block;
      margin-top: -1px;
      color: ${({ theme, verticalSlug }) =>
        ignoreFlyoutHoverList.includes(verticalSlug) ? 'none' : theme.colors.base};
      @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: none;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

const VerticalIconStyle = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;

  svg {
    max-width: 23px;
  }
`

const SectionHeadingWithColor = styled(SectionHeading)`
  flex: 1;

  color: ${({ verticalSlug, theme }) =>
    verticalSlug ? theme.colors[verticalSlug].DEFAULT : theme.colors.brand.DEFAULT};
`

const NavItems = ({
  verticals,
  vertical,
  showSubNav = false,
  className,
  disableFlyout = false,
  disableLink = false,
}) => {
  const [activeHoverMenu, setActiveHoverMenu] = useState(null)
  const verticalSlug = vertical?.slug?.replace('-insurance', '')
  const accountSlug = vertical?.parentUrl?.concat(vertical?.slug) || false

  return (
    <div className={className}>
      {vertical && (
        <>
          <Link href={`/${accountSlug || vertical.slug}`} passHref legacyBehavior>
            <VerticalAnchor
              color={verticalSlug}
              verticalSlug={verticalSlug}
              className={activeHoverMenu === -1 ? 'hovered' : ''}
              disableLink={disableLink}
            >
              <SectionHeadingWithColor verticalSlug={verticalSlug}>
                <VerticalIconStyle>
                  <VerticalIcon icon={verticalSlug} />
                </VerticalIconStyle>{' '}
                {vertical.title}
                {!disableFlyout && (
                  <span className="nav-item-inner">
                    <SvgPointer color={verticalSlug} />
                  </span>
                )}
              </SectionHeadingWithColor>
            </VerticalAnchor>
          </Link>
          {!ignoreFlyoutHoverList.includes(vertical.slug) && !disableFlyout && (
            <FlyoutNav
              vertical={vertical}
              mouseEnter={() => setActiveHoverMenu(-1)}
              mouseLeave={() => setActiveHoverMenu(0)}
            />
          )}
        </>
      )}

      {verticals?.length &&
        verticals.map((vertical, index) => {
          const color = vertical.slug.replace('-insurance', '')

          return (
            <Fragment key={index}>
              <Link href={`/${vertical.slug}`} passHref legacyBehavior>
                <NavItem
                  color={color}
                  isSelected={vertical.slug.replace('-insurance', '') === verticalSlug}
                  className={activeHoverMenu === index + 1 ? 'hovered' : ''}
                  verticalSlug={verticalSlug}
                >
                  <span className="nav-item-inner">
                    {vertical.title.replace(' Insurance', '')}
                    <SvgPointer color={color} />
                  </span>
                </NavItem>
              </Link>

              {showSubNav && (
                <FlyoutNav
                  vertical={vertical}
                  mouseEnter={() => setActiveHoverMenu(index + 1)}
                  mouseLeave={() => setActiveHoverMenu(0)}
                />
              )}
            </Fragment>
          )
        })}
      <Link href="/identity" passHref legacyBehavior>
        <NavItem
          color="identity"
          isSelected={'identity' === verticalSlug}
          // className={activeHoverMenu === index + 1 ? 'hovered' : ''}
          verticalSlug={verticalSlug}
        >
          <span className="nav-item-inner">Identity</span>
        </NavItem>
      </Link>
    </div>
  )
}

const NavItemsDesktop = styled(NavItems)`
  display: flex;
  padding: ${({ vertical }) => (vertical?.slug ? '0 40px 0 0' : '0 40px')};
  width: 100%;
`
const NavItemsMobile = styled(NavItems)``

const Navigation = ({
  verticals,
  vertical,
  desktopSearch,
  disableFlyout = false,
  disableLink = false,
  currentUser,
  handleSignOut,
}) => {
  return (
    <NavigationWrapper desktopSearch={desktopSearch}>
      <NavItemsDesktop
        verticals={verticals}
        vertical={vertical}
        showSubNav
        disableFlyout={disableFlyout}
        disableLink={disableLink}
      />
      <NavItemsMobile
        verticals={verticals}
        vertical={vertical}
        className="nav-items-mobile"
      />

      <SearchBar desktopSearch={desktopSearch} />

      <MobileNav>
        {!currentUser && (
          <ActionWrapper>
            <Link href="/login" passHref legacyBehavior>
              <StyledAnchor>Sign In</StyledAnchor>
            </Link>

            <Link href="/signup" passHref legacyBehavior>
              <StyledAnchor variant="secondary">Create Account</StyledAnchor>
            </Link>
          </ActionWrapper>
        )}

        {currentUser && (
          <ActionWrapper>
            <StyledButton onClick={handleSignOut}>Sign Out</StyledButton>

            <Link href="/account/settings/notifications" passHref legacyBehavior>
              <StyledAnchor variant="secondary">My Account</StyledAnchor>
            </Link>
          </ActionWrapper>
        )}
      </MobileNav>
    </NavigationWrapper>
  )
}

const ActionWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  > * {
    width: 100%;
    max-width: 50%;
  }
`

const MobileNav = styled.div``

function onShowSearch(showValue, hideValue) {
  return ({ desktopSearch }) => (desktopSearch.show ? showValue : hideValue)
}

const NavigationWrapper = styled.nav`
  flex: 1;
  display: flex;
  padding: 0 40px;

  ${MobileNav} {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    ${({ desktopSearch }) => desktopSearch.show && 'position: relative;'}
    ${MobileNav} {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    overflow-x: hidden; // TODO: make so can scroll on mobile without a scrollbar
    padding: 0;
    margin: 24px 0 24px 0;
    display: block;
  }

  ${NavItemsDesktop} {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: flex;
    }
  }

  ${NavItemsMobile} {
    display: flex;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: none;
    }
  }

  ${SearchBar} {
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      position: absolute;
      ${({ desktopSearch }) =>
        desktopSearch.show &&
        `
        top: -4px;
        right: -42px;
        bottom: 0;
      `}

      opacity: ${onShowSearch('1', '0')};
      z-index: ${onShowSearch('20', '10')};
      width: ${onShowSearch('100%', '0')};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: block;
      width: 100%;
      opacity: 1;
    }
  }
`

const NavItem = styled.a`
  display: ${({ verticalSlug }) => (verticalSlug ? 'none' : 'flex')};
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.base};
  padding: 0 1rem;

  cursor: pointer;

  span {
    position: relative;
    border-bottom: 3px solid ${({ theme }) => theme.colors.action.DEFAULT};
    display: flex;
    align-items: center;
    height: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      border: unset;
    }
  }

  &:hover,
  &.hovered {
    background: ${({ color, theme }) => theme.colors[color].DEFAULT};
    color: white;

    span.nav-item-inner {
      border-bottom: 3px solid ${({ color, theme }) => theme.colors[color].DEFAULT};
    }

    span ${StyledSVG} {
      display: block;
      @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: none;
      }
    }

    & + ${FlyoutNavWrapper} {
      display: block;
      color: ${({ theme }) => theme.colors.base};
      @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: none;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    padding: 0 12px;

    ${({ isSelected, color, theme }) =>
      isSelected &&
      `
    background: ${theme.colors[color].DEFAULT};
    color: white;
  `}
  }
`

export default Navigation
