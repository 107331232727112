import styled from 'styled-components'

function SvgPointer({ color }) {
  return (
    <StyledSVG
      color={color}
      width={17}
      height={8}
      viewBox="0 0 17 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.666 0l8 8h-16l8-8z" fill="currentColor" />
    </StyledSVG>
  )
}

export const StyledSVG = styled.svg`
  display: none;
  position: absolute;
  left: 50%;
  top: calc(100% + 19.3px);
  transform: translateX(-50%);
  color: ${({ color, theme }) => theme.colors[color].DEFAULT};
`

export default SvgPointer
