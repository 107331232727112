function SvgMedal(props) {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.969 4.094L5.812.5C5.657.219 5.282 0 4.97 0H1.5c-.406 0-.656.469-.438.813l3.5 4.968A6.411 6.411 0 017.97 4.094zM16.469 0H13a.985.985 0 00-.844.5L10 4.094c1.313.218 2.5.812 3.406 1.687l3.5-4.968C17.125.469 16.875 0 16.47 0zM9 5a5.488 5.488 0 00-5.5 5.5C3.5 13.563 5.938 16 9 16c3.031 0 5.5-2.438 5.5-5.5C14.5 7.469 12.031 5 9 5zm2.875 4.938l-1.188 1.156.282 1.625c.062.281-.25.5-.531.375L9 12.313l-1.469.78C7.25 13.22 6.938 13 7 12.72l.281-1.625-1.187-1.156a.37.37 0 01.187-.626l1.657-.218.718-1.5a.344.344 0 01.313-.188c.156 0 .281.063.344.188l.718 1.5 1.656.219a.37.37 0 01.188.624z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMedal
