const SvgSearch = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.752 15.559l-3.512-3.512c-.176-.14-.387-.246-.598-.246h-.561a7.323 7.323 0 001.545-4.496C14.626 3.301 11.324 0 7.32 0 3.28 0 .014 3.301.014 7.305a7.3 7.3 0 007.306 7.306 7.259 7.259 0 004.495-1.546v.597c0 .211.07.422.246.597l3.477 3.478c.351.35.878.35 1.194 0l.984-.984c.35-.316.35-.843.035-1.194zM7.32 11.801a4.482 4.482 0 01-4.496-4.496A4.504 4.504 0 017.32 2.81c2.459 0 4.496 2.037 4.496 4.495a4.504 4.504 0 01-4.496 4.496z"
      fill="currentColor"
    />
  </svg>
)

export default SvgSearch
