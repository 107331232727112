function SvgChevron({ rotate = 0, ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', transform: `rotate(${rotate}deg)` }}
      className="svg-chevron"
      {...props}
    >
      <path
        d="M13.432 10.451a.644.644 0 000-.902L8.127 4.217c-.273-.246-.684-.246-.93 0l-.629.629c-.246.246-.246.656 0 .93l4.211 4.21-4.21 4.239c-.247.273-.247.683 0 .93l.628.628c.246.246.657.246.93 0l5.305-5.332z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChevron
