import { useState } from 'react'

export function useSearchToggle(defaultValue) {
  const [show, setShow] = useState(defaultValue)

  const toggle = () => setShow((s) => !s)

  return { show, setShow, toggle }
}

export default useSearchToggle
